import {Theme} from 'themes/index';

export default function (theme: Theme) {
    return {
        theme,
        height: '1px',
        width: '100%',
        color: theme.palette?.grey['300'],
    };
}

import {Theme} from 'themes/index';
import STATE from '../../../globals';

export default function (theme: Theme) {
    return {
        theme,
        background: theme.palette?.grey['200'],
        fontFamily: theme.text?.fontFamily,
        fontSize: theme.text?.fontSize.m,
        lineHeight: theme.text?.lineHeight.m,
        color: theme.palette?.text.primary,
        borderRadius: (theme.gutter ?? 0) * 2 + 'px',
        placeholder: theme.palette?.text.secondary,
        fontWeight: theme?.text?.fontWeight.normal,
        textAlign: 'left',
        [STATE.HOVER]: {
            background: theme.palette?.grey['400'],
        },
        [STATE.FOCUS]: {
            background: theme.palette?.grey['200'],
        },
        [STATE.ERROR]: {
            background: theme.palette?.error['600'],
        },
        [STATE.DISABLED]: {
            opacity: 0.3,
        },
        label: {
            fontFamily: theme.text?.fontFamily,
            fontSize: theme.text?.fontSize.xs3,
            lineHeight: theme.text?.lineHeight.xs3,
            color: theme.palette?.text.secondary,
            [STATE.ERROR]: {
                color: theme.palette?.error['900'],
            },
        },
    };
}

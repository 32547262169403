import {Theme} from 'themes/index';

export default function (theme: Theme) {
    return {
        theme,
        fontFamily: theme.text?.fontFamily,
        fontSize: theme.text?.fontSize.xs3,
        lineHeight: theme.text?.lineHeight.xs3,
        borderRadius: '100px',
        paddingTop: '2px',
        paddingBottom: '2px',
        paddingRight: '6px',
        paddingLeft: '6px',
        minWidth: (theme.gutter ?? 1) * 2.5 + 'px',
        boxSizing: 'border-box',
        justifyContent: 'center',
        background: theme.palette?.grey['200'],
        color: theme.palette?.text.primary,
    };
}

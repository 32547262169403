'use client';
'use strict';
import React from 'react';
import Context from './context';

export interface ThemeProps {
    theme?: object;
}

function theme<P, R>(ComponentToWrap, componentName, displayName?: string) {
    const WithTheme = React.forwardRef<R, P>((props, ref) => (
        <Context.Consumer>
            {theme => (
                <ComponentToWrap
                    {...props}
                    theme={theme ? theme[componentName] : undefined}
                    ref={ref}
                />
            )}
        </Context.Consumer>
    ));

    WithTheme.displayName = displayName;

    return WithTheme;
}

export default theme;

import React, {useCallback, useEffect, useState} from 'react';
import {set} from './reducers/viewport';
import useEventListener from 'react-use-event-listener';
import {MAX_MOBILE, MAX_TABLET, MIN_TABLET} from '@/features/Application/reducers/viewport';
import {useDispatch} from 'react-redux';

const VIEWPORT_MOBILE = 'mobile';
const VIEWPORT_TABLET = 'tablet';
const VIEWPORT_DESKTOP = 'desktop';

export interface IProps {
    children: any;
}

const getType = () => {
    const width = document.body.offsetWidth;

    if (width <= MAX_MOBILE) {
        return VIEWPORT_MOBILE;
    } else if (width >= MIN_TABLET && width <= MAX_TABLET) {
        return VIEWPORT_TABLET;
    } else {
        return VIEWPORT_DESKTOP;
    }
};

const Responsive = ({children}: IProps) => {
    const [viewport, setViewport] = useState(getType());
    const handleResize = useCallback(() => {
        const nextViewport = getType();

        if (nextViewport !== viewport) {
            setViewport(nextViewport);
        }
    }, [viewport]);
    const dispatch = useDispatch();

    useEventListener('resize', handleResize, window);

    useEffect(() => {
        if (viewport) {
            dispatch(
                set({
                    isMobile: viewport === VIEWPORT_MOBILE,
                    isTablet: viewport === VIEWPORT_TABLET,
                    isLaptop: viewport === VIEWPORT_DESKTOP,
                }),
            );
        }
    }, [viewport]);

    return children;
};

export default Responsive;

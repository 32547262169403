import React from 'react';
import {Provider as ReduxProvider} from 'react-redux';
import store from '../../store';
import Application from './index';
import {BrowserRouter} from 'react-router-dom';
import ThemeProvider from '@ff/web-components/components/themeProvider';
import {ThemeProvider as StyledThemeProvider} from 'styled-components';
import useTheme from '@/hooks/useTheme';
import Responsive from './responsive';

const AppProvider = () => {
    const theme = useTheme();

    return (
        <React.StrictMode>
            <ReduxProvider store={store}>
                <BrowserRouter>
                    <ThemeProvider>
                        <StyledThemeProvider theme={theme}>
                            <Responsive>
                                <Application />
                            </Responsive>
                        </StyledThemeProvider>
                    </ThemeProvider>
                </BrowserRouter>
            </ReduxProvider>
        </React.StrictMode>
    );
};

export default AppProvider;

import styled from 'styled-components';

interface RootProps {
    $theme;
    $overlay;
}

const Root = styled.div<RootProps>`
    ${({$theme, $overlay}) => ({
        ...$theme,
        boxShadow:
            $theme.boxShadow || ($overlay ? $theme.theme.palette?.shadow.default : undefined),
        overflow: $overlay ? 'hidden' : 'unset',
    })};
`;

export default Root;

'use client';
'use strict';
import React, {useId} from 'react';
import Animation from './style/animation';
import Circle from './style/circle';
import theme from '../theme';
import rootTheme from './theme';
import Box from '../Box';
import Svg from './style/svg';

interface ColorGradient {
    offset: string;
    color: string;
}

export interface IProps {
    size?: string | number;
    color?: string;
    gradient?: ColorGradient[];
    theme?: Partial<ReturnType<typeof rootTheme>>;
}

const SpinnerMain = React.forwardRef<HTMLDivElement, IProps>(
    ({theme, size, color, gradient}: IProps, ref) => {
        const id = useId();
        return (
            <Box w={size || theme?.size} h={size || theme?.size} ref={ref}>
                <Animation>
                    <Svg viewBox="26 26 52 52">
                        {Array.isArray(gradient) ? (
                            <defs>
                                <linearGradient id={id} x1="0%" y1="0%" x2="0%" y2="100%">
                                    {gradient.map(({offset, color}) => (
                                        <stop offset={offset} stopColor={color} key={offset} />
                                    ))}
                                </linearGradient>
                            </defs>
                        ) : null}
                        <Circle
                            $color={gradient ? `url(#${id})` : color || theme?.color}
                            cx="52px"
                            cy="52px"
                            r="20px"
                            fill="none"
                            strokeWidth={theme?.strokeWidth}
                        />
                    </Svg>
                </Animation>
            </Box>
        );
    },
);

SpinnerMain.displayName = 'Spinner';

export default theme<IProps, HTMLDivElement>(SpinnerMain, 'spinner', 'Spinner');

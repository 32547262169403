'use client';
'use strict';
import React from 'react';
import Root from './styles/root';
import theme from '../theme';
import rootTheme from './theme';

export interface IProps extends React.HTMLAttributes<HTMLDivElement> {
    color?:
        | 'primary'
        | 'secondary'
        | 'error'
        | 'link'
        | 'white'
        | 'black'
        | 'contrast'
        | 'contrast.secondary'
        | 'contrast.link'
        | 'disabled'
        | string;
    align?: 'left' | 'right' | 'center';
    // font-size + line-height
    size?:
        | 'xs5'
        | 'xs4'
        | 'xs3'
        | 'xs2'
        | 'xs'
        | 's'
        | 'm'
        | 'l'
        | 'xl'
        | 'xl2'
        | 'xl3'
        | 'xl4'
        | 'xl5'
        | 'xl6'
        | 'xl7';
    // line-height
    lh?:
        | 'xs5'
        | 'xs4'
        | 'xs3'
        | 'xs2'
        | 'xs'
        | 's'
        | 'm'
        | 'l'
        | 'xl'
        | 'xl2'
        | 'xl3'
        | 'xl4'
        | 'xl5'
        | 'xl6'
        | 'xl7'
        | string;
    // font-size
    fs?:
        | 'xs5'
        | 'xs4'
        | 'xs3'
        | 'xs2'
        | 'xs'
        | 's'
        | 'm'
        | 'l'
        | 'xl'
        | 'xl2'
        | 'xl3'
        | 'xl4'
        | 'xl5'
        | 'xl6'
        | 'xl7'
        | string;
    fw?: 400 | 500 | 600 | 'normal' | 'semi' | 'bold';
    // font-weight: semi-bold
    semi?: boolean;
    // font-weight: bold
    bold?: boolean;
    children?: React.ReactNode;
    noWrap?: boolean;
    ws?: 'normal' | 'nowrap' | 'pre' | 'pre-wrap' | 'pre-line' | 'break-spaces';
    ellipsis?: boolean;
    // text-overflow
    to?: 'clip' | 'ellipsis';
    overflow?: 'visible' | 'hidden' | 'scroll' | 'auto' | 'inherit';
    // text-decoration
    td?: string;
    cursor?: string;
    // text-transform
    tt?: 'capitalize' | 'uppercase' | 'lowercase' | 'none' | 'full-width' | 'full-size-kana';
    theme?: Partial<ReturnType<typeof rootTheme>>;
    // font-family
    ff?: string;
}

const TextMain = React.forwardRef<HTMLDivElement, IProps>(
    (
        {
            theme,
            color,
            align,
            size,
            lh,
            fs,
            semi,
            bold,
            fw,
            children,
            noWrap,
            to,
            ws,
            overflow,
            ellipsis,
            td,
            cursor,
            tt,
            ff,
            ...rest
        }: IProps,
        ref,
    ) => (
        <Root
            {...rest}
            $semi={semi}
            $bold={bold}
            $color={color}
            $align={align}
            $size={size}
            $lh={lh}
            $fs={fs}
            $fw={fw}
            $noWrap={noWrap}
            $to={to}
            $ws={ws}
            $overflow={overflow}
            $ellipsis={ellipsis}
            $td={td}
            $cursor={cursor}
            $tt={tt}
            $ff={ff}
            $theme={theme?.theme}
            ref={ref}
        >
            {children}
        </Root>
    ),
);

TextMain.displayName = 'Text';

export default theme<IProps, HTMLDivElement>(TextMain, 'text', 'Text');

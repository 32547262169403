import React from 'react';
import useLang from '@/hooks/useLang';
import Box from '@ff/web-components/components/Box';

const AppStore = () => {
    const lang = useLang();
    return (
        <Box df>
            <img
                src={
                    lang === 'ru'
                        ? '/img/markets/app-store-ru.svg'
                        : '/img/markets/app-store-az.svg'
                }
                alt="App Store"
                width={108}
                height={32}
            />
        </Box>
    );
};

export default AppStore;

import STATE from '../../../globals';
import primary from './primary';
import {Theme} from 'themes/index';

export default function (theme: Theme) {
    return {
        ...primary(theme),
        background: theme.palette?.grey['200'],
        color: theme.palette?.text.link,
        [STATE.HOVER]: {
            background: theme.palette?.grey['400'],
        },
        [STATE.DISABLED]: {
            color: theme.palette?.text.disabled,
            background: theme.palette?.grey['100'],
        },
        pressed: {
            background: theme.palette?.grey['400'],
            color: theme.palette?.text.link,
        },
    };
}

import primary from './primary';
import STATE from '../../../globals';
import {Theme} from 'themes/index';

export default function (theme: Theme) {
    return {
        ...primary(theme),
        background: theme.palette?.error['900'],
        color: theme.palette?.text.contrast.primary,
        [STATE.HOVER]: {
            background: theme.palette?.error['1000'],
        },
    };
}

import STATE from '../../../globals';
import primary from './primary';
import {Theme} from 'themes/index';

export default function (theme: Theme) {
    return {
        ...primary(theme),
        background: theme.palette?.error['700'],
        color: theme.palette?.error['900'],
        [STATE.HOVER]: {
            background: theme.palette?.error['800'],
        },
        [STATE.DISABLED]: {
            background: theme.palette?.error['600'],
            color: theme.palette?.error['800'],
        },
        pressed: {
            background: theme.palette?.error['800'],
        },
    };
}

import React from 'react';
import theme from '../theme';
import defaultTheme from './theme';
export interface IProps {
    color?: string;
    width?: string;
    height?: string;
    theme?: Partial<ReturnType<typeof defaultTheme>>;
}

const s = {display: 'flex'};

const CheckboxUnchecked: React.FC<IProps> = ({
    color,
    width = '24px',
    height = '24px',
    theme,
}: IProps) => {
    color = color || ('#000' === '#000' ? theme.theme?.palette?.grey['1000'] : '#000');

    return (
        <div style={s}>
            <svg
                height={height}
                width={width}
                fill="none"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
            >
                <rect
                    height="16"
                    width="16"
                    rx="2"
                    stroke={color}
                    strokeOpacity=".3"
                    strokeWidth="2"
                    x="4"
                    y="4"
                />
            </svg>
        </div>
    );
};

export default theme<IProps, HTMLDivElement>(CheckboxUnchecked, 'icon', 'CheckboxUnchecked');

import STATE from '../../../globals';
import {Theme} from 'themes/index';

export default function (theme: Theme) {
    return {
        theme,
        fontFamily: theme.text?.fontFamily,
        fontSize: theme.text?.fontSize.xs2,
        lineHeight: theme.text?.lineHeight.xs2,
        borderRadius: '20px',
        paddingTop: (theme.gutter ?? 1) + 'px',
        paddingBottom: (theme.gutter ?? 1) + 'px',
        paddingRight: (theme.gutter ?? 1) * 2 + 'px',
        paddingLeft: (theme.gutter ?? 1) * 2 + 'px',
        boxSizing: 'border-box',
        background: theme.palette?.grey['200'],
        color: theme.palette?.text.primary,
        transitionProperty: 'transform, background, color',
        transitionDuration: theme.transition?.duration,
        transitionTimingFunction: theme.transition?.timingFunction,
        [STATE.HOVER]: {
            background: theme.palette?.grey['400'],
        },
        [STATE.ACTIVE]: {
            transform: 'scale(0.98)',
        },
        [STATE.DISABLED]: {
            color: theme.palette?.text.disabled,
            background: theme.palette?.grey['100'],
        },
    };
}

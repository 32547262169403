import React from 'react';

export interface IProps {
    color?: string;
    width?: string;
    height?: string;
    icon: React.ReactElement;
}

const ButtonIcon = ({icon, ...rest}: IProps) =>
    typeof icon === 'function'
        ? React.createElement(icon, {...rest})
        : React.cloneElement(icon, {...rest});

export default ButtonIcon;

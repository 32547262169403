import React from 'react';
import useLang from '@/hooks/useLang';
import Box from '@ff/web-components/components/Box';

const PlayMarket = () => {
    const lang = useLang();
    return (
        <Box df>
            <img
                src={
                    lang === 'ru'
                        ? '/img/markets/google-play-ru_2x.png'
                        : '/img/markets/google-play-az_2x.png'
                }
                alt="Play Market"
                width={108}
                height={32}
            />
        </Box>
    );
};

export default PlayMarket;

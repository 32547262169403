import styled from 'styled-components';
import {containerRotate} from './keyframes';

interface CircleProps {
    $color;
}

const Circle = styled.circle<CircleProps>`
    animation: ${containerRotate} 1.4s ease-in-out 0s infinite;
    stroke: ${({$color}) => $color};
    stroke-dasharray: 80px, 200px;
    stroke-dashoffset: 0;
    stroke-linecap: square;
    transform: translateZ(0);
`;

export default Circle;

import {Theme} from 'themes/index';
import STATE from '../../../globals';

export default function small(theme: Theme) {
    return {
        theme,
        width: (theme.gutter ?? 0) * 5 + 'px',
        height: (theme.gutter ?? 0) * 5 + 'px',
        background: theme.palette?.grey['200'],
        padding: theme.gutter + 'px',
        color: theme.palette?.grey['1000'],
        transformOrigin: 'center center',
        [STATE.HOVER]: {
            background: theme.palette?.grey['400'],
        },
        [STATE.ACTIVE]: {
            transform: 'scale(0.96)',
        },
    };
}

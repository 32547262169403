import {Theme} from 'themes/index';

export default function (theme: Theme) {
    return {
        theme,
        background: theme.palette?.grey['200'],
        mask: {
            background: `linear-gradient(90deg, transparent, ${theme.palette?.grey['200']}, transparent)`,
        },
    };
}

import {Theme} from 'themes/index';
import STATE from '../../../globals';

export default function primary(theme: Theme) {
    return {
        theme,
        width: '52px',
        height: '52px',
        background: theme.palette?.background.accent,
        padding: '10px',
        color: theme.palette?.background.default,
        transformOrigin: 'center center',
        [STATE.HOVER]: {
            background: theme.palette?.background.accent,
        },
        [STATE.ACTIVE]: {
            transform: 'scale(0.96)',
        },
    };
}

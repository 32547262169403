import styled from 'styled-components';
import {preloaderRotate} from './keyframes';

const Animation = styled.div`
    animation: ${preloaderRotate} 1.4s linear 0s infinite;
    z-index: 2;
    width: 100%;
    height: 100%;
`;

export default Animation;

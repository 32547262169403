import {Theme} from 'themes/index';
import primary from './primary';
import small from './small';
import secondary from './secondary';

export default function (theme: Theme) {
    return {
        theme,
        primary: primary(theme),
        small: small(theme),
        secondary: secondary(theme),
    };
}

'use client';
'use strict';
import React, {useMemo} from 'react';
import primaryTheme from './theme/primary';
import merge from 'themes/utils/merge';
import rootTheme from './theme';
import Root from './style/root';
import theme from '../theme';
import smallMixin from './mixins/small';
import Box from '../Box';
import Icon, {IProps as IconProps} from './icon';
import roundMixin from './mixins/round';
import wideMixin from './mixins/wide';
import {DeepPartial} from 'themes/index';

type IButtonNative = Omit<React.ButtonHTMLAttributes<HTMLButtonElement>, 'style'>;

export interface IProps extends IButtonNative {
    style?: DeepPartial<ReturnType<typeof primaryTheme>>;
    theme?: Partial<ReturnType<typeof rootTheme>>;
    children?: React.ReactNode;
    primary?: boolean;
    secondary?: boolean;
    warning?: boolean;
    round?: boolean;
    disabled?: boolean;
    small?: boolean;
    startIcon?: React.ReactElement | React.ComponentType;
    endIcon?: React.ReactElement | React.ComponentType;
    wide?: boolean;
    pressed?: boolean;
}

const ButtonMain = React.forwardRef<HTMLButtonElement, IProps>(
    (
        {
            children,
            style,
            theme,
            disabled,
            secondary,
            warning,
            primary,
            round,
            small,
            startIcon,
            endIcon,
            wide,
            pressed,
            ...rest
        }: IProps,
        ref,
    ) => {
        const computedTheme = useMemo(() => {
            let computed = merge(
                secondary ? theme?.secondary : warning ? theme?.warning : theme?.primary,
                style,
            );

            if (small) {
                computed = smallMixin(computed);
            }
            if (round) {
                computed = roundMixin(computed, {small});
            }
            if (wide) {
                computed = wideMixin(computed);
            }

            return computed;
        }, [style, theme, secondary, primary, warning, round, small]);

        return (
            <Root $theme={computedTheme} disabled={disabled} $pressed={pressed} ref={ref} {...rest}>
                {startIcon &&
                (React.isValidElement(startIcon) || typeof startIcon === 'function') ? (
                    <Box mr={children || endIcon ? 1 : 0} ml={children || endIcon ? -1 : 0}>
                        <Icon
                            icon={startIcon as React.ReactElement}
                            color={
                                typeof startIcon === 'function'
                                    ? primary
                                        ? computedTheme.theme.text.palette.contrast.primary
                                        : computedTheme.theme.text.palette.primary
                                    : (startIcon.props as IconProps).color
                            }
                            width={
                                typeof startIcon === 'function'
                                    ? computedTheme.theme.gutter * 2.5 + 'px'
                                    : (startIcon.props as IconProps).width
                            }
                            height={
                                typeof startIcon === 'function'
                                    ? computedTheme.theme.gutter * 2.5 + 'px'
                                    : (startIcon.props as IconProps).height
                            }
                        />
                    </Box>
                ) : null}
                {children}
                {endIcon && (React.isValidElement(endIcon) || typeof endIcon === 'function') ? (
                    <Box ml={children || startIcon ? 1 : 0} mr={children || startIcon ? -1 : 0}>
                        <Icon
                            icon={endIcon as React.ReactElement}
                            color={
                                typeof endIcon === 'function'
                                    ? primary
                                        ? computedTheme.theme.palette.text.contrast.primary
                                        : computedTheme.theme.palette.text.primary
                                    : (endIcon.props as IconProps).color
                            }
                            width={
                                typeof endIcon === 'function'
                                    ? computedTheme.theme.gutter * 2.5 + 'px'
                                    : (endIcon.props as IconProps).width
                            }
                            height={
                                typeof endIcon === 'function'
                                    ? computedTheme.theme.gutter * 2.5 + 'px'
                                    : (endIcon.props as IconProps).height
                            }
                        />
                    </Box>
                ) : null}
            </Root>
        );
    },
);

ButtonMain.displayName = 'Button';

export default theme<IProps, HTMLButtonElement>(ButtonMain, 'button', 'Button');

'use client';
import React from 'react';
import userAgent from '@ff/web-components/utils/userAgent';
import AppStore from '@/components/downloadApp/AppStore';
import PlayMarket from '@/components/downloadApp/PlayMarket';
import Box from '@ff/web-components/components/Box';

const DownloadApps = () => {
    return (
        <Box df jc="center">
            <a
                href={
                    userAgent.os.isIOS
                        ? 'https://apps.apple.com/ua/app/leobank/id1574700070'
                        : 'https://play.google.com/store/apps/details?id=com.ftfarm.leo'
                }
            >
                {userAgent.os.isIOS ? <AppStore /> : <PlayMarket />}
            </a>
        </Box>
    );
};

export default DownloadApps;

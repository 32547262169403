import checkedIcon from '../../Icon/CheckboxChecked';
import partialCheckedIcon from '../../Icon/CheckboxPartialChecked';
import uncheckedIcon from '../../Icon/CheckboxUnchecked';
import {Theme} from 'themes/index';

export default function (theme: Theme) {
    return {
        theme,
        checkedIcon,
        partialCheckedIcon,
        uncheckedIcon,
        fontFamily: theme.text?.fontFamily,
        fontSize: theme.text?.fontSize.xs,
        lineHeight: (theme.gutter ?? 0) * 3 + 'px',
        color: theme.palette?.text.secondary,
    };
}

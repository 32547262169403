import React from 'react';
import {Route, Routes} from 'react-router-dom';
import './style/reset.css';
import './style/global.css';
import Root from './style/root';
import withLocale from '@/hocs/withLocale';
import Box from '@ff/web-components/components/Box';
import GradientSpinner from '@/components/GradientSpinner';
import Download from '@/features/Download';

const ru = () => import(/* webpackChunkName: "localeRU" */ '../../locale/ru.json');
const az = () => import(/* webpackChunkName: "localeAZ" */ '../../locale/az.json');

const Application = () => {
    return (
        <Root>
            <Routes>
                <Route path="/">
                    <Route path="moneybox">
                        <Route path=":hash" element={<Download />} />
                    </Route>
                    <Route path="" element={<Download />} />
                </Route>
            </Routes>
        </Root>
    );
};

export default withLocale(Application, {
    ru,
    az,
    fallback: (
        <Box w="100%" h="100vh" df jc="center" ai="center">
            <GradientSpinner size="64px" />
        </Box>
    ),
});

import React, {useEffect} from 'react';
import {Dictionary} from '@/locale';
import Paper from '@ff/web-components/components/Paper';
import Root from './style/root';
import Box from '@ff/web-components/components/Box';
import Text from '@ff/web-components/components/Text';
import Button from '@ff/web-components/components/Button';
import DownloadApps from './downloadApps';
import {getLocale} from '@ff/web-components/utils/l10n';
import {useLocation} from 'react-router-dom';
import userAgent from '@ff/web-components/utils/userAgent';

const Download = () => {
    const locale: Dictionary = getLocale();
    const location = useLocation();
    const [, feature, hash] = location.pathname.split('/');
    const appLink = feature ? `leo://${feature}?hash=${hash}` : 'leo://';

    useEffect(() => {
        setTimeout(() => {
            if (!userAgent.os.isIOS) {
                window.location.href = 'market://details?id=com.ftfarm.leo';
            } else if (
                !(userAgent.os.isIOS && window.document.referrer.indexOf('apps.apple.com') > 0)
            ) {
                window.location.href = appLink;
            }
        }, 250);
    }, []);

    return (
        <Root>
            <Box mb={4}>
                <Paper px={2.5} py={4} rad="32px" maxW={41} bs="border-box">
                    <Box df jc="center" ai="center" mb={2}>
                        <img src="/img/app.svg" alt="Leobank" />
                    </Box>
                    <Box mb={3}>
                        <Text size="xl" bold align="center">
                            {locale.index.text}
                        </Text>
                    </Box>
                    <a href={appLink}>
                        <Button primary wide>
                            {locale.index.openBtn}
                        </Button>
                    </a>
                </Paper>
            </Box>
            <Box mb={2}>
                <Text size="xs" color="secondary" align="center">
                    {locale.index.downloadHint}
                </Text>
            </Box>
            <DownloadApps />
        </Root>
    );
};

export default Download;

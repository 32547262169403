import dm from 'deepmerge';

export default function merge(theme, style) {
    if (theme && style) {
        if (typeof style === 'object') {
            return dm(theme, style);
        } else {
            return theme;
        }
    } else {
        return theme;
    }
}

import styled from 'styled-components';

const calc = (gutter, multiplier) => {
    if (!isNaN(gutter) && !isNaN(multiplier)) {
        return gutter * multiplier + 'px';
    } else {
        return undefined;
    }
};

interface RootProps {
    $palette;
    $m;
    $mb;
    $mt;
    $ml;
    $mr;
    $mx;
    $my;
    $p;
    $py;
    $px;
    $pt;
    $pr;
    $pb;
    $pl;
    $df;
    $dif;
    $jc;
    $ai;
    $fd;
    $flex;
    $order;
    $fg;
    $fs;
    $fw;
    $fb;
    $ff;
    $as;
    $ac;
    $w;
    $h;
    $dib;
    $va;
    $minW;
    $maxW;
    $minH;
    $maxH;
    $bs;
    $pos;
    $t;
    $r;
    $b;
    $l;
    $center;
    $of;
    $rad;
    $gap;
}

const Root = styled.div<RootProps>`
    ${({
        $palette,
        $m,
        $mb,
        $mt,
        $ml,
        $mr,
        $mx,
        $my,
        $p,
        $py,
        $px,
        $pt,
        $pr,
        $pb,
        $pl,
        $df,
        $dif,
        $jc,
        $ai,
        $fd,
        $flex,
        $order,
        $fg,
        $fs,
        $fw,
        $fb,
        $ff,
        $as,
        $ac,
        $w,
        $h,
        $dib,
        $va,
        $minW,
        $maxW,
        $minH,
        $maxH,
        $bs,
        $pos,
        $t,
        $r,
        $b,
        $l,
        $center,
        $of,
        $rad,
        $gap,
    }) => {
        let margin: undefined | string = undefined;
        let padding: undefined | string = undefined;
        const gutter = $palette.gutter ?? 1;

        if ($mx || $my) {
            const xAxis = !isNaN($mx) ? calc(gutter, $mx) : $mx || 'auto';
            const yAxis = !isNaN($my) ? calc(gutter, $my) : $my || 'auto';
            margin = `${yAxis} ${xAxis} `;
        }

        if ($center) {
            margin = '0 auto';
        }

        if (!isNaN($m)) {
            margin = calc(gutter, $m);
        } else if ($m) {
            margin = $m;
        }

        if ($px || $py) {
            const xAxis = !isNaN($px) ? calc(gutter, $px) : $px || '0';
            const yAxis = !isNaN($py) ? calc(gutter, $py) : $py || '0';
            padding = `${yAxis} ${xAxis} `;
        }

        if (!isNaN($p)) {
            padding = calc(gutter, $p);
        } else if ($p) {
            padding = $p;
        }

        const result: any = {
            margin,
            marginTop: calc(gutter, $mt),
            marginRight: calc(gutter, $mr),
            marginBottom: calc(gutter, $mb),
            marginLeft: calc(gutter, $ml),
            padding,
            paddingTop: calc(gutter, $pt),
            paddingRight: calc(gutter, $pr),
            paddingBottom: calc(gutter, $pb),
            paddingLeft: calc(gutter, $pl),
            gap: calc(gutter, $gap),
            width: typeof $w === 'string' ? $w : !isNaN($w) ? calc(gutter, $w) : undefined,
            height: typeof $h === 'string' ? $h : !isNaN($h) ? calc(gutter, $h) : undefined,
        };

        if ($df) {
            result.display = 'flex';
        } else if ($dif) {
            result.display = 'inline-flex';
        } else if ($dib) {
            result.display = 'inline-block';
        }

        if ($jc) {
            result.justifyContent = $jc;
        }
        if ($ai) {
            result.alignItems = $ai;
        }
        if ($fd) {
            result.flexDirection = $fd;
        }
        if ($flex) {
            result.flex = $flex;
        }
        if ($order) {
            result.order = $order;
        }
        if ($fg) {
            result.flexGrow = $fg;
        }
        if ($fs !== undefined) {
            result.flexShrink = $fs;
        }
        if ($fw) {
            result.flexWrap = $fw;
        }
        if ($fb) {
            result.flexBasis = $fb;
        }
        if ($ff) {
            result.flexFlow = $ff;
        }
        if ($as) {
            result.alignSelf = $as;
        }
        if ($ac) {
            result.alignContent = $ac;
        }
        if ($va) {
            result.verticalAlign = $va;
        }
        if ($minW) {
            result.minWidth =
                typeof $minW === 'string' ? $minW : !isNaN($minW) ? calc(gutter, $minW) : undefined;
        }
        if ($maxW) {
            result.maxWidth =
                typeof $maxW === 'string' ? $maxW : !isNaN($maxW) ? calc(gutter, $maxW) : undefined;
        }
        if ($minH) {
            result.minHeight =
                typeof $minH === 'string' ? $minH : !isNaN($minH) ? calc(gutter, $minH) : undefined;
        }
        if ($maxH) {
            result.maxHeight =
                typeof $maxH === 'string' ? $maxH : !isNaN($maxH) ? calc(gutter, $maxH) : undefined;
        }

        if ($bs) {
            result.boxSizing = $bs;
        }

        if ($pos) {
            result.position = $pos;
        }

        if ($t) {
            result.top = $t;
        }
        if ($b) {
            result.bottom = $b;
        }
        if ($r) {
            result.right = $r;
        }
        if ($l) {
            result.left = $l;
        }
        if ($of) {
            result.overflow = $of;
        }
        if ($rad) {
            result.borderRadius = $rad;
        }

        return result;
    }};
`;

export default Root;

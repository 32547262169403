export const LIGHT_MODE = 'light';

const theme = {
    palette: {
        mode: LIGHT_MODE,
        text: {
            primary: '#000',
            secondary: 'rgba(0,0,0,0.5)',
            link: '#000',
            disabled: 'rgba(0,0,0,0.3)',
            contrast: {
                primary: '#FFF',
                secondary: 'rgba(255,255,255,0.5)',
                link: 'rgba(174, 135, 224, 1)',
            },
        },
        background: {
            default: '#FFF',
            b1: '#F0F3F7',
            b2: '#FFF',
            accent: '#000',
            accentToner: 'rgba(0, 0, 0, 0.80)',
        },
        grey: {
            1000: '#000',
            900: 'rgba(0,0,0,0.8)',
            800: 'rgba(0,0,0,0.5)',
            700: 'rgba(0,0,0,0.4)',
            600: 'rgba(0,0,0,0.3)',
            500: 'rgba(0,0,0,0.2)',
            400: 'rgba(0,0,0,0.12)',
            300: 'rgba(0,0,0,0.08)',
            200: 'rgba(0,0,0,0.06)',
            100: 'rgba(0,0,0,0.04)',
        },
        black: '#000',
        white: '#FFF',
        accent: {
            greenApple: 'rgba(0, 199, 76, 1)',
            strawberryPeach: 'rgba(255, 141, 112, 1)',
            orangePeach: 'rgba(245, 166, 49, 1)',
            redSalmon: 'rgba(245, 71, 59, 1)',
            cobaltBlue: 'rgba(9, 116, 255, 1)',
        },
        error: {
            1000: 'rgba(221, 57, 46, 1)',
            900: 'rgba(245, 71, 59, 1)',
            800: 'rgba(245, 71, 59, 0.18)',
            700: 'rgba(245, 71, 59, 0.12)',
            600: 'rgba(245, 71, 59, 0.04)',
        },
        shadow: {
            default: '0px 2px 8px 0px rgba(0, 0, 0, 0.12)',
        },
    },
    text: {
        fontFamily: '"Inter", sans-serif',
        fontSize: {
            xl7: '48px',
            xl6: '40px',
            xl5: '32px',
            xl4: '28px',
            xl3: '26px',
            xl2: '23px',
            xl: '20px',
            l: '18px',
            m: '16px',
            s: '15px',
            xs: '14px',
            xs2: '13px',
            xs3: '12px',
            xs4: '11px',
            xs5: '10px',
        },
        lineHeight: {
            xl7: '56px',
            xl6: '48px',
            xl5: '40px',
            xl4: '36px',
            xl3: '34px',
            xl2: '28px',
            xl: '28px',
            l: '24px',
            m: '22px',
            s: '20px',
            xs: '20px',
            xs2: '18px',
            xs3: '16px',
            xs4: '14px',
            xs5: '14px',
        },
        fontWeight: {
            bold: 600,
            semi: 500,
            normal: 400,
        },
    },
    transition: {
        duration: '450ms',
        timingFunction: 'cubic-bezier(0.23, 1, 0.32, 1)',
    },
    zIndex: {
        maximal: 1001,
        overlay: 999,
    },
    gutter: 8,
};

export default theme;

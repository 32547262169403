import {createSlice} from '@reduxjs/toolkit';
import cookie from 'js-cookie';
import {DEFAULT_LANG} from '@/features/Application/constants';
import moment from 'moment';
import runtime from '@/store/runtime';

export type LANG = 'az' | 'ru';

export const initialState: LANG =
    (cookie.get('lang') as LANG) ??
    (window.navigator.language === 'ru-RU' || window.navigator.language?.toLowerCase() === 'ru'
        ? 'ru'
        : DEFAULT_LANG);
runtime.set('LANG', initialState);
moment.locale(initialState);

const lang = createSlice({
    name: 'lang',
    initialState,
    reducers: {
        set(state, action) {
            if (action.payload !== state) {
                moment.locale(action.payload);
                cookie.set('lang', action.payload, {expires: 365});
                runtime.set('LANG', action.payload);
                return action.payload;
            } else {
                return state;
            }
        },
    },
});

export const {set} = lang.actions;
export default lang.reducer;

export const isTouchDevice = () => {
    return (
        typeof window !== 'undefined' &&
        ('ontouchstart' in window ||
            (window['DocumentTouch'] && document instanceof window['DocumentTouch']) ||
            window.matchMedia(
                '(-webkit-touch-enabled),(-moz-touch-enabled),(-o-touch-enabled),(-ms-heartz)',
            ).matches)
    );
};

const navigator = typeof window !== 'undefined' ? window.navigator : null;
const ua = ((navigator && navigator.userAgent) || '').toLowerCase();
const os = {
    name: '',
    isUnix: /unix/.test(ua),
    isLinux: /linux/.test(ua),
    isIrix: /irix/.test(ua),
    isMac: /mac/.test(ua),
    isSun: /sun/.test(ua),
    isFreeBSD: /freebsd/.test(ua),
    isWindows: /win/.test(ua),
    isWindowsPhone: /windows\s+phone/.test(ua),
    isIOS: /(iphone|ipad)/i.test(ua),
    isAndroid: /android/.test(ua),
    isTouch: isTouchDevice(),
};

if (os.isUnix) {
    os.name = 'Unix';
} else if (os.isLinux) {
    os.name = 'Linux';
} else if (os.isIrix) {
    os.name = 'Irix';
} else if (os.isMac) {
    os.name = 'MacOS';
} else if (os.isSun) {
    os.name = 'SunOS';
} else if (os.isFreeBSD) {
    os.name = 'FreeBSD';
} else if (os.isWindows) {
    if (/95/.test(ua)) {
        os.name = 'Windows 95';
    } else if (/98/.test(ua)) {
        os.name = 'Windows 98';
    } else if (/2000/.test(ua)) {
        os.name = 'Windows 2000';
    } else if (/windows\s+nt\s+6\.1/.test(ua)) {
        os.name = 'Windows 7';
    } else if (/windows\s+nt\s+6\.2/.test(ua)) {
        os.name = 'Windows 8';
    } else if (/windows\s+nt\s+6\.0/.test(ua)) {
        os.name = 'Windows Vista';
    } else if (/Windows/.test(ua)) {
        os.name = 'Windows XP';
    } else {
        os.name = 'Windows 3.1';
    }
}

const version =
    (/(?:msie\s([0-9a-z,.]+);)/i.exec(ua) ||
        /trident.*rv:([0-9a-z,.]+)/i.exec(ua) || // msie
        /(?:version\/([0-9a-z,.]+))$/.exec(ua) || // opera
        /(?:chrome\/([0-9a-z,.]+)\s)/.exec(ua) || // chrome
        /(?:firefox\/([0-9a-z,.]+))$/.exec(ua) || // firefox
        /(?:version\/([0-9a-z,.]+)\s)/.exec(ua) || // safari
        [])[1] || null;
let versionArr,
    versionAsNumber = 0;

if (version) {
    // (14.0b.1)
    versionArr = version.replace(/[^0-9.,]/, '').split(/[.,]/);
    versionAsNumber = +(versionArr.shift() + '.' + versionArr.join('')) || versionAsNumber;
}

const browser: any = {
    version: version,
    versionAsNumber: versionAsNumber,
    isIPhone: /iphone/i.test(ua),
    isIPad: /ipad/i.test(ua),
    isIPod: /ipod/i.test(ua),
    isIPadWebView: /applewebkit/.test(ua) && /mobile/.test(ua),
    isOpera: /'opera'/.test(ua),
    isChrome: /chrome/i.test(ua) || ((os.isIOS || os.isMac) && /CriOS/i.test(ua)),
    isFireFox: /firefox/.test(ua),
    isIE: /msie|trident/i.test(ua),
    isAndroidWebView: /(Android.*wv)/i.test(ua),
    isIOSWebView: /(iPhone|iPod|iPad).*AppleWebKit(?!.*Safari)/i.test(ua),
    isEdge: /edge/i.test(ua),
};

browser.isWebView = browser.isAndroidWebView || browser.isIOSWebView;
browser.isSafari = !browser.isChrome && /safari/i.test(ua);

browser.isMobileDevice =
    browser.isIPad ||
    browser.isIPod ||
    browser.isIPhone ||
    browser.isIPadWebView ||
    os.isAndroid ||
    os.isIOS ||
    os.isWindowsPhone ||
    os.isTouch;

const language = navigator ? navigator.language.split('-')[0].toLowerCase() : '';

export default {
    language,
    browser,
    os,
};

import {Theme} from 'themes/index';

export default function (theme: Theme) {
    return {
        theme,
        fontSize: theme.text?.fontSize.xs2,
        lineHeight: theme.text?.lineHeight.xs2,
        color: theme.palette?.text.secondary,
        background: theme.palette?.background.b2,
    };
}

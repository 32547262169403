import {createSlice} from '@reduxjs/toolkit';

export const MAX_MOBILE = 760;
export const MIN_TABLET = 761;
export const MAX_TABLET = 942;

const w = document.body.offsetWidth;

const initialState = {
    isMobile: w <= MAX_MOBILE,
    isTablet: w >= MIN_TABLET && w >= MAX_TABLET,
    isLaptop: w > MAX_TABLET,
};

const viewport = createSlice({
    name: 'viewport',
    initialState,
    reducers: {
        set(state, action) {
            return action.payload;
        },
    },
});

export const {set} = viewport.actions;
export default viewport.reducer;

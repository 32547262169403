import {Theme} from 'themes/index';
import STATE from '../../../globals';

export default function (theme: Theme) {
    return {
        theme,
        fontFamily: theme.text?.fontFamily,
        fontSize: theme.text?.fontSize.xs,
        lineHeight: theme.text?.lineHeight.xs,
        fontWeight: theme.text?.fontWeight.bold,
        paddingTop: '10px',
        paddingBottom: '10px',
        paddingRight: (theme.gutter ?? 0) * 2 + 'px',
        paddingLeft: (theme.gutter ?? 0) * 2 + 'px',
        color: theme.palette?.text.secondary,
        background: 'transparent',
        borderRadius: (theme.gutter ?? 0) * 2.5 + 'px',
        [STATE.CHECKED]: {
            background: theme.palette?.background.accent,
            color: theme.palette?.text.contrast.primary,
        },
    };
}

import primary from './primary';
import {Theme} from 'themes/index';
import secondary from './secondary';
import warning from './warning';

export default function (theme: Theme) {
    return {
        primary: primary(theme),
        secondary: secondary(theme),
        warning: warning(theme),
    };
}

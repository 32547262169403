import defaultTheme from '../theme/primary';

export default function (theme: ReturnType<typeof defaultTheme>, {small}) {
    return {
        ...theme,
        width: (theme.theme.gutter ?? 0) * (small ? 5 : 6.5) + 'px',
        height: (theme.theme.gutter ?? 0) * (small ? 5 : 6.5) + 'px',
        minHeight: 'auto',
        borderRadius: '50%',
    };
}

import {Theme} from 'themes/index';

export default function (theme: Theme) {
    return {
        theme,
        color: theme.palette?.grey['1000'],
        size: (theme.gutter ?? 0) * 2.5 + 'px',
        strokeWidth: '5px',
    };
}

import {Theme} from 'themes/index';
import primary from './primary';
import warning from './warning';

export default function (theme: Theme) {
    return {
        theme,
        width: (theme.gutter ?? 0) * 42 + 'px',
        paddingTop: (theme.gutter ?? 0) * 3 + 'px',
        snack: {
            primary: primary(theme),
            warning: warning(theme),
        },
    };
}

import styled from 'styled-components';

const Root = styled.div`
    min-height: 100vh;
    ${({theme}) => ({
        fontFamily: theme.c.text.fontFamily,
        fontSize: theme.c.text.fontSize.m,
        lineHeight: theme.c.text.lineHeight.m,
        color: theme.c.palette.text.primary,
        background: theme.p.palette.background.page,
    })}
`;

export default Root;

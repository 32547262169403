import styled from 'styled-components';
import type {Theme} from 'themes/index';

interface RootProps {
    $theme;
    $color;
    $align;
    $size;
    $lh;
    $fs;
    $semi;
    $bold;
    $fw;
    $noWrap;
    $to;
    $ws;
    $overflow;
    $ellipsis;
    $td;
    $cursor;
    $tt;
    $ff;
}

const Root = styled.div<RootProps>`
    ${({
        $theme,
        $color,
        $align,
        $size,
        $lh,
        $fs,
        $semi,
        $bold,
        $fw,
        $noWrap,
        $to,
        $ws,
        $overflow,
        $ellipsis,
        $td,
        $cursor,
        $tt,
        $ff,
    }) => {
        const palette = $theme?.palette || ({} as Theme);
        let textColor;
        let fontSize;
        let lineHeight;
        let fontWeight;
        let whiteSpace;
        let textOverflow;
        let overflowStyle;
        let textTransform;

        if ($color === 'primary') {
            textColor = palette.text.primary;
        } else if ($color === 'secondary') {
            textColor = palette.text.secondary;
        } else if ($color === 'contrast') {
            textColor = palette.text.contrast.primary;
        } else if ($color === 'contrast.secondary') {
            textColor = palette.text.contrast.secondary;
        } else if ($color === 'contrast.link') {
            textColor = palette.text.contrast.link;
        } else if ($color === 'error') {
            textColor = palette.error['900'];
        } else if ($color === 'link') {
            textColor = palette.text.link;
        } else if ($color === 'white') {
            textColor = palette.white;
        } else if ($color === 'black') {
            textColor = palette.black;
        } else if ($color === 'disabled') {
            textColor = palette.text.disabled;
        } else if (typeof $color === 'string') {
            textColor = $color;
        }

        if ($size === 'xs5') {
            fontSize = $theme.text.fontSize.xs5;
            lineHeight = $theme.text.lineHeight.xs5;
        } else if ($size === 'xs4') {
            fontSize = $theme.text.fontSize.xs4;
            lineHeight = $theme.text.lineHeight.xs4;
        } else if ($size === 'xs3') {
            fontSize = $theme.text.fontSize.xs3;
            lineHeight = $theme.text.lineHeight.xs3;
        } else if ($size === 'xs2') {
            fontSize = $theme.text.fontSize.xs2;
            lineHeight = $theme.text.lineHeight.xs2;
        } else if ($size === 'xs') {
            fontSize = $theme.text.fontSize.xs;
            lineHeight = $theme.text.lineHeight.xs;
        } else if ($size === 's') {
            fontSize = $theme.text.fontSize.s;
            lineHeight = $theme.text.lineHeight.s;
        } else if ($size === 'm') {
            fontSize = $theme.text.fontSize.m;
            lineHeight = $theme.text.lineHeight.m;
        } else if ($size === 'l') {
            fontSize = $theme.text.fontSize.l;
            lineHeight = $theme.text.lineHeight.l;
        } else if ($size === 'xl') {
            fontSize = $theme.text.fontSize.xl;
            lineHeight = $theme.text.lineHeight.xl;
        } else if ($size === 'xl2') {
            fontSize = $theme.text.fontSize.xl2;
            lineHeight = $theme.text.lineHeight.xl2;
        } else if ($size === 'xl3') {
            fontSize = $theme.text.fontSize.xl3;
            lineHeight = $theme.text.lineHeight.xl3;
        } else if ($size === 'xl4') {
            fontSize = $theme.text.fontSize.xl4;
            lineHeight = $theme.text.lineHeight.xl4;
        } else if ($size === 'xl5') {
            fontSize = $theme.text.fontSize.xl5;
            lineHeight = $theme.text.lineHeight.xl5;
        } else if ($size === 'xl6') {
            fontSize = $theme.text.fontSize.xl6;
            lineHeight = $theme.text.lineHeight.xl6;
        } else if ($size === 'xl7') {
            fontSize = $theme.text.fontSize.xl7;
            lineHeight = $theme.text.lineHeight.xl7;
        }

        if ($fs === 'xs5') {
            fontSize = $theme.text.fontSize.xs5;
        } else if ($fs === 'xs4') {
            fontSize = $theme.text.fontSize.xs4;
        } else if ($fs === 'xs3') {
            fontSize = $theme.text.fontSize.xs3;
        } else if ($fs === 'xs2') {
            fontSize = $theme.text.fontSize.xs2;
        } else if ($fs === 'xs') {
            fontSize = $theme.text.fontSize.xs;
        } else if ($fs === 's') {
            fontSize = $theme.text.fontSize.s;
        } else if ($fs === 'm') {
            fontSize = $theme.text.fontSize.m;
        } else if ($fs === 'l') {
            fontSize = $theme.text.fontSize.l;
        } else if ($fs === 'xl') {
            fontSize = $theme.text.fontSize.xl;
        } else if ($fs === 'xl2') {
            fontSize = $theme.text.fontSize.xl2;
        } else if ($fs === 'xl3') {
            fontSize = $theme.text.fontSize.xl3;
        } else if ($fs === 'xl4') {
            fontSize = $theme.text.fontSize.xl4;
        } else if ($fs === 'xl5') {
            fontSize = $theme.text.fontSize.xl5;
        } else if ($fs === 'xl6') {
            fontSize = $theme.text.fontSize.xl6;
        } else if ($fs === 'xl7') {
            fontSize = $theme.text.fontSize.xl7;
        } else if (typeof $fs === 'string') {
            fontSize = $fs;
        }

        if ($lh === 'xs5') {
            lineHeight = $theme.text.lineHeight.xs5;
        } else if ($lh === 'xs4') {
            lineHeight = $theme.text.lineHeight.xs4;
        } else if ($lh === 'xs3') {
            lineHeight = $theme.text.lineHeight.xs3;
        } else if ($lh === 'xs2') {
            lineHeight = $theme.text.lineHeight.xs2;
        } else if ($lh === 'xs') {
            lineHeight = $theme.text.lineHeight.xs;
        } else if ($lh === 's') {
            lineHeight = $theme.text.lineHeight.s;
        } else if ($lh === 'm') {
            lineHeight = $theme.text.lineHeight.m;
        } else if ($lh === 'l') {
            lineHeight = $theme.text.lineHeight.l;
        } else if ($lh === 'xl') {
            lineHeight = $theme.text.lineHeight.xl;
        } else if ($lh === 'xl2') {
            lineHeight = $theme.text.lineHeight.xl2;
        } else if ($lh === 'xl3') {
            lineHeight = $theme.text.lineHeight.xl3;
        } else if ($lh === 'xl4') {
            lineHeight = $theme.text.lineHeight.xl4;
        } else if ($lh === 'xl5') {
            lineHeight = $theme.text.lineHeight.xl5;
        } else if ($lh === 'xl6') {
            lineHeight = $theme.text.lineHeight.xl6;
        } else if ($lh === 'xl7') {
            lineHeight = $theme.text.lineHeight.xl7;
        } else if (typeof $lh === 'string') {
            lineHeight = $lh;
        }

        if ($fw) {
            fontWeight =
                typeof $fw === 'number'
                    ? $fw
                    : $theme.text.fontWeight[$fw] || $theme.text.fontWeight.normal;
        }
        if ($semi) {
            fontWeight = $theme.text.fontWeight.semi;
        } else if ($bold) {
            fontWeight = $theme.text.fontWeight.bold;
        }

        if ($noWrap) {
            whiteSpace = 'nowrap';
        } else if ($ws) {
            whiteSpace = $ws;
        }

        if ($ellipsis) {
            textOverflow = 'ellipsis';
            overflowStyle = 'hidden';
        } else if ($to) {
            textOverflow = $to;
        }

        if ($overflow) {
            overflowStyle = $overflow;
        }

        if ($tt) {
            textTransform = $tt;
        }

        return {
            color: textColor || undefined,
            textAlign: $align || undefined,
            fontSize,
            lineHeight,
            fontWeight,
            whiteSpace,
            textOverflow,
            textTransform,
            overflow: overflowStyle,
            display: $align !== undefined ? 'block' : 'inline-block',
            textDecoration: $td || undefined,
            cursor: $cursor || undefined,
            fontFamily: $ff || $theme.text.fontFamily || undefined,
        };
    }};
`;

export default Root;

let hash = {};

/**
 * Получим тип формы слова для количественного определения. Например, 6 карт, 1 карта, 2 карты...
 * Соответственно, firstForm -> 'карта', secondForm -> 'карты', thirdForm -> 'карт'
 * @param {number} number
 * @param {object} options
 * @return {string}
 */
const _getWordFormByNumber = function (number = 0, options: {isSecond?: boolean} = {}) {
    let key = 'firstForm';
    const mod = number % 10;

    if (mod >= 2 && mod <= 4) {
        if (number % 100 < 10 || number % 100 > 20) {
            key = 'secondForm';
        } else {
            key = 'thirdForm';
        }
        // ищем 11 и другие числа,попадающие по третью форму
    } else if (number === 0 || mod !== 1 || (mod === 1 && (number - 11) % 100 === 0)) {
        key = 'thirdForm';
    }

    if (options.isSecond) {
        if (key === 'firstForm') {
            key = 'secondForm';
        } else if (key === 'secondForm') {
            key = 'thirdForm';
        }
    }

    return key;
};

/**
 * Заменяет {attributeName:first word form|second form|third form}
 * на одну из указанных форм слова, в зависимости от значения из options
 * @param {String} str
 * @param {Object} options
 * @return {String}
 */
export const wordForm = function (str: string, options: {[index: string]: string | number}) {
    if (typeof options !== 'object') {
        return str;
    }

    return str.replace(
        /{([a-z][a-z0-9]*):([^|]+)\|([^|]+)\|([^|]+)}/gi,
        (pattern, variableName, firstForm, secondForm, thirdForm) => {
            if (options[variableName] === null) {
                return '';
            }
            if (isNaN(+options[variableName])) {
                return firstForm;
            }

            switch (_getWordFormByNumber(+options[variableName])) {
                case 'secondForm':
                    return secondForm;
                case 'thirdForm':
                    return thirdForm;
                default:
                    return firstForm;
            }
        },
    );
};

/**
 * Заменяет {attributeName} на значение из options
 * @param {String} str
 * @param {Object} options
 * @return {String}
 */
export const placeholder = function (str, options = {}) {
    return str.replace(/{([a-z][a-z0-9]*)}/gi, function (pattern, variableName) {
        if (options[variableName] === null || options[variableName] === undefined) {
            return '';
        }

        return options[variableName].toString();
    });
};

const _helpers = [placeholder, wordForm];

/**
 * Заменяет значения, обрабатываемые l10nHelpers
 * @param {String} str
 * @param {Object} options
 * @return {string}
 * @private
 */
const _processL10nHelpers = function (str = '', options = {}) {
    // ускорялка
    if (str.indexOf('{') > -1 && _helpers && _helpers.length) {
        _helpers.forEach(helper => {
            str = helper(str, options);
        });
    }

    return str;
};

export const setLocale = function (data = {}, options: {merge?: boolean} = {}) {
    const {merge = false} = options;
    hash = merge ? {...hash, ...data} : data;
};

export const getLocale = function (): any {
    return hash;
};

/**
 * Process pattern with all l10n helpers
 * */
export const t = (pattern: string, data?: {[key: string]: string}): string => {
    if (!pattern || !data) {
        return '';
    } else {
        let result = pattern;

        _helpers.forEach(helper => {
            result = helper(result, data);
        });

        return result;
    }
};

/**
 * Выдает переведенную строку непосредственно для вставки в шаблон
 * @param {String} key
 * @param {Object} [options]
 * @return {String}
 */
const translate = function (key: string, options?: {[key: string]: string}) {
    let translated = '';

    if (key) {
        key = String(key);
        translated = (hash && hash[key]) || key;

        return _processL10nHelpers(translated, options);
    }

    return translated;
};

export default {
    t: translate,
    translate,
    setLocale,
    getLocale,
};

import React from 'react';
import Spinner, {IProps} from '@ff/web-components/components/Spinner';

const GradientSpinner = (props: IProps) => (
    <Spinner
        {...props}
        gradient={[
            {
                color: 'rgba(255, 143, 236, 1)',
                offset: '0%',
            },
            {
                color: 'rgba(133, 165, 255, 1)',
                offset: '100%',
            },
        ]}
    />
);

export default GradientSpinner;

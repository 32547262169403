import {keyframes} from 'styled-components';

export const preloaderRotate = keyframes`
    100% {
        transform: rotate(360deg); 
    } 
`;
export const containerRotate = keyframes`
    0% {
        stroke-dasharray: 1px, 200px;
        stroke-dashoffset: 0; 
    } 
    50% {
        stroke-dasharray: 100px, 200px;
        stroke-dashoffset: -15px;
    } 
    100% {
        stroke-dasharray: 100px, 200px;
        stroke-dashoffset: -120px;
    }
`;

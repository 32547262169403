import {MAX_MOBILE, MAX_TABLET} from '@/features/Application/reducers/viewport';

const theme = {
    palette: {
        background: {
            page: '#E9ECF5',
        },
        secondary: {
            salmon: '#FF6B6B',
            juicyPeach: '#FF957A',
            lightOrange: '#FF9D57',
            tuscanSun: '#F5BD4E',
            oliveGreen: '#C7C758',
            yellowGreen: '#88C758',
            turquoise: '#39CC7E',
            turkish: '#18CCB4',
            lightSkyBlue: '#4BC5EB',
            blueOcean: '#5297FF',
            slateblue: '#7A80FF',
            mediumPurple: '#A983FB',
            sweetTomato: '#FF7092',
            hotPink: '#FF75D0',
            indianRed: '#D16976',
            sienna: '#9D7067',
            burlyWood: '#CCA966',
            persian: '#51B8B1',
            independence: '#566DCC',
            slategrey: '#8095A8',
            // with opacity
            blueOcean20: 'rgba(82, 151, 255, 0.20)',
        },
        hex: {
            black: {
                50: '#808080',
            },
        },
    },
    size: {
        desktop: {
            min: MAX_TABLET + 'px',
        },
        mobile: {
            min: '0',
            max: MAX_MOBILE + 'px',
        },
    },
    media: {
        mobile: `@media only screen and (min-width: 0) and (max-width: ${MAX_MOBILE}px)`,
    },
    responsive: {
        contentWidth: () => `
            width: ${MAX_TABLET}px;
            @media only screen and (min-width: 0) and (max-width: ${MAX_MOBILE}px) {
                width: 100%;
            }
        `,
    },
};

export default theme;
export type Theme = Partial<typeof theme>;

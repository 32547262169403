export const STATE = {
    IDLE: 'idle',
    HOVER: 'hover',
    FOCUS: 'focus',
    ERROR: 'error',
    DISABLED: 'disabled',
    ACTIVE: 'active',
    CHECKED: 'checked',
};

export default STATE;

import {Theme} from 'themes/index';

export default function (theme: Theme) {
    return {
        theme,
        overflow: 'visible',
        zIndex: theme.zIndex?.maximal,
        overlay: {
            background: 'rgba(0,0,0,0.5)',
        },
    };
}

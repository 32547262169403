'use client';
'use strict';
import React from 'react';
import theme from '../theme';
import defaultTheme from './theme';
import useMergeTheme from '../../hooks/useMergeTheme';
import Root from './style/root';
import type {IProps as BoxProps} from '../Box';
import Box from '../Box';
import {DeepPartial} from 'themes/index';

export interface IProps extends Omit<BoxProps, 'style' | 'theme'> {
    style?: DeepPartial<ReturnType<typeof defaultTheme>> | React.CSSProperties;
    theme?: Partial<ReturnType<typeof defaultTheme>>;
    children?: React.ReactNode;
    overlay?: boolean;
}

const PaperMain = React.forwardRef<HTMLDivElement, IProps>(
    ({style, theme, children, overlay, ...rest}: IProps, ref) => {
        const computedTheme = useMergeTheme(theme, style);
        return (
            <Root ref={ref} $theme={computedTheme} $overlay={overlay}>
                <Box {...rest}>{children}</Box>
            </Root>
        );
    },
);

PaperMain.displayName = 'Paper';

export default theme<IProps, HTMLDivElement>(PaperMain, 'paper', 'Paper');

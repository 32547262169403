import styled from 'styled-components';
import STATE from '../../../globals';

interface RootProps {
    $theme;
    $pressed;
}

const Root = styled.button<RootProps>`
    outline: none;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    ${({$theme, disabled, $pressed}) => {
        return {
            ...$theme,
            cursor: disabled ? 'not-allowed' : 'pointer',
            $theme: undefined,
            '&:hover': disabled ? undefined : $theme[STATE.HOVER],
            '&:active': disabled ? undefined : $theme[STATE.ACTIVE],
            ...($pressed && !disabled ? $theme.pressed : {}),
            '&[disabled]': $theme[STATE.DISABLED],
        };
    }};
`;

export default Root;

import STATE from '../../../globals';
import {Theme} from 'themes/index';

export default function (theme: Theme) {
    return {
        theme,
        width: 'auto',
        background: theme.palette?.background.accent,
        color: theme.palette?.text.contrast.primary,
        borderRadius: '40px',
        fontFamily: theme.text?.fontFamily,
        fontSize: theme.text?.fontSize.m,
        lineHeight: theme.text?.lineHeight.m,
        fontWeight: theme.text?.fontWeight.bold,
        paddingTop: '0',
        paddingBottom: '0',
        paddingLeft: (theme.gutter ?? 0) * 2.5 + 'px',
        paddingRight: (theme.gutter ?? 0) * 2.5 + 'px',
        minHeight: (theme.gutter ?? 0) * 6.5 + 'px',
        transitionProperty: 'transform, background, color',
        transitionDuration: theme.transition?.duration,
        transitionTimingFunction: theme.transition?.timingFunction,
        transformOrigin: 'center center',
        [STATE.ACTIVE]: {
            transform: 'scale(0.98)',
        },
        [STATE.HOVER]: {
            background: theme.palette?.background.accent,
        },
        [STATE.DISABLED]: {
            color: theme.palette?.text.disabled,
            background: theme.palette?.grey['100'],
        },
        pressed: {
            background: theme.palette?.background.accent,
        },
    };
}

'use client';
'use strict';
import React from 'react';
import Root from './styles/root';
import theme from '../theme';
import rootTheme from './theme';

export interface IProps extends React.HTMLAttributes<HTMLDivElement> {
    // display: flex
    df?: boolean;
    // display: inline-flex
    dif?: boolean;
    // display: inline-block
    dib?: boolean;
    // justify-content
    jc?:
        | 'start'
        | 'end'
        | 'flex-start'
        | 'flex-end'
        | 'center'
        | 'left'
        | 'right'
        | 'normal'
        | 'baseline'
        | 'space-between'
        | 'space-around'
        | 'space-evenly'
        | 'stretch'
        | 'safe'
        | 'unsafe';
    // align-items
    ai?: 'flex-start' | 'flex-end' | 'center' | 'normal' | 'baseline' | 'stretch';
    // flex-direction
    fd?: 'row' | 'row-reverse' | 'column' | 'column-reverse';
    // flex
    flex?: string | number;
    // flex order
    order?: string | number;
    // flex-grow
    fg?: string | number;
    // flex-shrink
    fs?: string | number;
    // flex-wrap
    fw?: 'nowrap' | 'wrap' | 'wrap-reverse';
    // flex-basis
    fb?: string | number;
    // flex-flow
    ff?:
        | 'row'
        | 'row-reverse'
        | 'column'
        | 'column-reverse'
        | 'nowrap'
        | 'wrap'
        | 'wrap-reverse'
        | string;
    // align-self
    as?:
        | 'auto'
        | 'flex-start'
        | 'flex-end'
        | 'center'
        | 'normal'
        | 'baseline'
        | 'last baseline'
        | 'first baseline'
        | 'stretch'
        | 'safe'
        | 'unsafe'
        | 'self-end'
        | 'self-start';
    // align-content
    ac?:
        | 'start'
        | 'end'
        | 'flex-start'
        | 'flex-end'
        | 'center'
        | 'normal'
        | 'baseline'
        | 'first baseline'
        | 'last baseline'
        | 'space-around'
        | 'space-evenly'
        | 'safe'
        | 'unsafe';
    // margin
    m?: string | number;
    // margin-bottom
    mb?: string | number;
    // margin-top
    mt?: string | number;
    // margin-left
    ml?: string | number;
    // margin-right
    mr?: string | number;
    // margin-left + margin-right
    mx?: string | number;
    // margin-top + margin-bottom
    my?: string | number;
    // padding
    p?: string | number;
    // padding-top + padding-bottom
    py?: string | number;
    // padding-left + padding-right
    px?: string | number;
    // padding-top
    pt?: string | number;
    // padding-right
    pr?: string | number;
    // padding-bottom
    pb?: string | number;
    // padding-left
    pl?: string | number;
    // width
    w?: string | number;
    // height
    h?: string | number;
    // minWidth
    minW?: string | number;
    // maxWidth
    maxW?: string | number;
    // minHeight
    minH?: string | number;
    // maxHeight
    maxH?: string | number;
    children?: React.ReactNode;
    // vertical-align
    va?:
        | 'baseline'
        | 'sub'
        | 'super'
        | 'text-top'
        | 'text-bottom'
        | 'middle'
        | 'top'
        | 'bottom'
        | 'inherit'
        | 'initial'
        | 'unset';
    bs?: 'border-box' | 'content-box';
    pos?: 'relative' | 'absolute' | 'static' | 'fixed';
    t?: string | number;
    r?: string | number;
    b?: string | number;
    l?: string | number;
    center?: boolean;
    of?: 'hidden' | 'visible' | 'scroll' | 'auto';
    rad?: string;
    theme?: Partial<ReturnType<typeof rootTheme>>;
    gap?: string | number;
}

const BoxMain = React.forwardRef<HTMLDivElement, IProps>(
    (
        {
            m,
            mb,
            mt,
            ml,
            mr,
            mx,
            my,
            p,
            py,
            px,
            pt,
            pr,
            pb,
            pl,
            df,
            dif,
            jc,
            ai,
            fd,
            flex,
            order,
            fg,
            fs,
            fw,
            fb,
            ff,
            as,
            ac,
            children,
            w,
            h,
            dib,
            theme,
            va,
            minW,
            maxW,
            minH,
            maxH,
            bs,
            pos,
            t,
            r,
            b,
            l,
            center,
            of,
            rad,
            gap,
            ...rest
        }: IProps,
        ref,
    ) => {
        return (
            <Root
                {...rest}
                $palette={theme?.theme}
                $dib={dib}
                $df={df}
                $dif={dif}
                $jc={jc}
                $ai={ai}
                $fd={fd}
                $flex={flex}
                $order={order}
                $fg={fg}
                $fs={fs}
                $fw={fw}
                $fb={fb}
                $ff={ff}
                $as={as}
                $ac={ac}
                $m={m}
                $mb={mb}
                $mt={mt}
                $ml={ml}
                $mr={mr}
                $mx={mx}
                $my={my}
                $p={p}
                $py={py}
                $px={px}
                $pt={pt}
                $pr={pr}
                $pb={pb}
                $pl={pl}
                $w={w}
                $h={h}
                ref={ref}
                $va={va}
                $minW={minW}
                $maxW={maxW}
                $minH={minH}
                $maxH={maxH}
                $bs={bs}
                $pos={pos}
                $t={t}
                $r={r}
                $b={b}
                $l={l}
                $center={center}
                $of={of}
                $rad={rad}
                $gap={gap}
            >
                {children}
            </Root>
        );
    },
);

BoxMain.displayName = 'Box';

export default theme<IProps, HTMLDivElement>(BoxMain, 'box', 'Box');

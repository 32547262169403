import defaultTheme from '../theme/primary';

export default function (theme: ReturnType<typeof defaultTheme>) {
    return {
        ...theme,
        width: 'auto',
        minHeight: (theme.theme.gutter ?? 0) * 5 + 'px',
        fontSize: theme.theme.text?.fontSize.xs,
        lineHeight: theme.theme.text?.lineHeight.xs,
        fontWeight: theme.theme.text?.fontWeight.normal,
        color: theme.theme.palette?.text.secondary,
    };
}

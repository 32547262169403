import {Theme} from 'themes/index';

export default function (theme: Theme) {
    return {
        theme,
        fontFamily: theme.text?.fontFamily,
        fontSize: theme.text?.fontSize.xs,
        lineHeight: theme.text?.lineHeight.xs,
        color: theme.palette?.text.secondary,
        option: {
            fontFamily: theme.text?.fontFamily,
            fontSize: theme.text?.fontSize.xs,
            lineHeight: theme.text?.lineHeight.xs,
            color: theme.palette?.text.secondary,
            fontWeight: theme.text?.fontWeight.bold,
            paddingRight: '14px',
            paddingLeft: '14px',
            paddingTop: '6px',
            paddingBottom: '6px',
        },
    };
}

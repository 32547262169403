import {Theme} from 'themes/index';

export default function (theme: Theme) {
    return {
        theme,
        textAlign: 'center',
        fontFamily: theme.text?.fontFamily,
        fontSize: theme.text?.fontSize.xs,
        lineHeight: theme.text?.lineHeight.xs,
        color: theme.palette?.white,
        padding: '5px',
        borderRadius: (theme.gutter ?? 0) + 'px',
        background: theme.palette?.accent.greenApple,
    };
}

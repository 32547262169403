import primary from './primary';
import warning from './warning';
import {Theme} from 'themes/index';

export default function (theme: Theme) {
    return {
        primary: primary(theme),
        warning: warning(theme),
    };
}

import small from './small';
import {Theme} from 'themes/index';
import primary from './primary';

export default function (theme: Theme) {
    return {
        theme,
        primary: primary(theme),
        small: small(theme),
    };
}

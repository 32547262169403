import React, {useEffect, useState} from 'react';
import l10n from '@ff/web-components/utils/l10n';
import Spinner from '@ff/web-components/components/Spinner';
import {useSelector} from 'react-redux';
import Button from '@ff/web-components/components/Button';
import Box from '@ff/web-components/components/Box';
import {RootState} from '@/store';
import Text from '@ff/web-components/components/Text';

const getLang = ({application}: RootState) => application.lang;

export default (
    Component: React.ComponentType<any>,
    {
        ru,
        az,
        fallback = <Spinner />,
    }: {ru: () => Promise<any>; az: () => Promise<any>; fallback?: React.ReactNode},
): React.ComponentType<any> => {
    return function WithLocale(props: any) {
        const lang = useSelector(getLang)?.toLowerCase();
        const [state, setState] = useState({lang: [], error: null, loading: false});

        useEffect(() => {
            const list = {
                ru,
                az,
            };
            const fetcher = async () => {
                const toLoad = list[lang as keyof typeof list];

                if (toLoad) {
                    try {
                        setState(prev => ({...prev, loading: true}));
                        const data = await toLoad();
                        l10n.setLocale(data?.default || data, {merge: true});
                        setState(prev => ({
                            lang: [...prev.lang, lang],
                            error: null,
                            loading: false,
                        }));
                    } catch (error) {
                        setState(prev => ({...prev, error, loading: false}));
                    }
                } else {
                    /* eslint-env node */
                    if (process.env.NODE_ENV !== 'production') {
                        window.console.error(`Locale ${lang} not found in withLocale.`);
                    }
                }
            };

            fetcher();
        }, [lang]);

        return state.loading ? (
            fallback
        ) : state.error ? (
            <>
                <Box mb={1}>
                    <Text color="error" size="xl">
                        Failed to load locale {lang}
                    </Text>
                </Box>
                <div>
                    <Button warning onClick={() => window.location.reload()}>
                        Repeat
                    </Button>
                </div>
            </>
        ) : state.lang.includes(lang) ? (
            <Component {...props} />
        ) : (
            fallback
        );
    } as React.ComponentType<any>;
};
